import { useState, useContext } from "react";
import { Outlet, NavLink, Link } from "react-router-dom";
import Modal from "react-modal";
import { useCookies } from 'react-cookie'

import { UserContext } from '../contexts/user.js';

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        width: window.innerWidth > 2000 ? '25%' : '40%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgb(28, 28, 28)',
        border: 'none',
        height: 'fit-content',
        padding: '20px',
        maxHeight: '90%'
    },
};

const AuthModal = (props) => {
    const { modalIsOpen, setIsOpen, user, setUser } = props;
    const [loginCommand, setLoginCommand] = useState("loading");
    ///weblogin avlx0w83ksx983jdxj
    const [selectedSignIn, setSelectedSignIn] = useState(false);
    const [authError, setAuthError] = useState(false);
    const [authSuccess, setAuthSuccess] = useState(false);
    const [forceUsernameChange, setForceUsernameChange] = useState(false);
    const [copyText, setCopyText] = useState('Copy');
    const [cookies, setCookie] = useCookies(['loggedin', 'user_id', 'username', 'admin']);

    const closeModal = () => {
        setSelectedSignIn(false);
        setIsOpen(false);
    };

    const selectTelegramLogin = () => {
        (async() => {
            const authSession = await fetch("https://api-v2.aquabot.io/auth/telegram/create", { method: "POST", credentials: "include" }).then(r => r.json());
            if(authSession.success === false) {
                setAuthError(authSession.message);
                return;
            }

            setLoginCommand(`/weblogin ${authSession.session_id}`);

            const interval = setInterval(async() => {
                const authSessionStatus = await fetch(`https://api-v2.aquabot.io/auth/telegram?code=${authSession.session_id}`, { method: "GET", credentials: "include" }).then(r => r.json());
                if(authSessionStatus.success === false) {
                    clearInterval(interval);
                    setAuthError(authSessionStatus.message);
                    return;
                };

                console.log(authSessionStatus);

                if(authSessionStatus.authenticated === false) return;
                setUser({
                    loggedin: true,
                    id: authSessionStatus.user_id,
                    username: authSessionStatus.username,
                    admin: authSessionStatus.admin
                });

                let expires = new Date();
                expires.setTime(expires.getTime() + (259200 * 1000));
                setCookie('loggedin', "true", { path: '/',  expires});
                setCookie('user_id', authSessionStatus.user_id, {path: '/', expires});
                setCookie('username', authSessionStatus.username, { path: '/', expires });
                setCookie('admin', authSessionStatus.admin, { path: '/', expires });

                setAuthSuccess(`@${authSessionStatus.username}`);
                setForceUsernameChange(authSessionStatus.force_username_change);
                clearInterval(interval);
            }, 2500);
        })();
    };

    const setUsername = async() => {
        const usernameText = document.getElementById("username-input").value;

        const data = await fetch(`https://api-v2.aquabot.io/user/username`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST", 
            credentials: "include", 
            body: JSON.stringify({ username: usernameText }) 
        }).then(r => r.json());
        if(data.success == false) {
            alert(data.message);
            return false;
        };

        setUser({ ...user, username: usernameText });
        setAuthSuccess(`@${usernameText}`);
        setForceUsernameChange(false);
    };

    const setSelectedSignInApp = (app) => {
        setSelectedSignIn(app);
        if(app === "telegram") {
            selectTelegramLogin();
        }
    };

    const modalBackToMain = () => {
        setSelectedSignIn(false);
        setAuthError(false);
        setAuthSuccess(false);
        setLoginCommand("loading");
    };
    
    const copyCode = () => {
        window.navigator.clipboard.writeText(loginCommand);
        setCopyText('Copied!');
        setTimeout(() => setCopyText('Copy'), 1000);
    };

    if(forceUsernameChange !== false) {
        return (
            <Modal isOpen={modalIsOpen} style={modalStyles} contentLabel="Login Modal">
                <div className="modal-error">
                    <p className="banner-box-icon"><i className="fa-solid fa-user"></i></p>
                    <p className="banner-box-title modal-error-title">Please set a username</p>
                    <p className="banner-box-text modal-error-text">Your username will be shown on leaderboards, skin checks, and your profile</p>
                    <div>
                        <input type="text" className="b-input" id="username-input" placeholder="Username" />
                    </div>
                    <div>
                        <button className="model-bottom-btn primary-btn mt-2" style={{ background: "#0278F4" }} onClick={() => setUsername()}>Submit</button>
                    </div>
                </div>
            </Modal>
        )
    }

    if(authSuccess !== false) {
        return (
            <Modal isOpen={modalIsOpen} style={modalStyles} contentLabel="Login Modal">
                <div className="modal-error">
                    <p className="banner-box-icon"><i className="fa-solid fa-circle-check"></i></p>
                    <p className="banner-box-title modal-error-title">Welcome, {authSuccess}</p>
                    <p className="banner-box-text modal-error-text">You've succesfully authenticated, you can now manage your skin checks and much more from the web dashboard!</p>
                    <div>
                        <Link to="/dashboard"><button className="model-bottom-btn primary-btn mt-2" style={{ background: "#0278F4" }} onClick={() => closeModal()}>Web Dashboard</button></Link>
                    </div>
                </div>
            </Modal>
        )
    }

    if(authError !== false) {
        return (
            <Modal isOpen={modalIsOpen} style={modalStyles} contentLabel="Login Modal">
                <div className="modal-error">
                    <p className="banner-box-icon"><i className="fa-solid fa-circle-exclamation"></i></p>
                    <p className="banner-box-title modal-error-title">An error occurred</p>
                    <p className="banner-box-text modal-error-text">{authError}</p>
                    <div>
                        <button className="model-bottom-btn primary-btn mt-2" onClick={() => modalBackToMain()}>Go Back</button>
                    </div>
                </div>
            </Modal>
        )
    }

    return (
        <Modal isOpen={modalIsOpen} style={modalStyles} contentLabel="Login Modal">
                {selectedSignIn === 'telegram' ? 
                <div>                    
                    <span className="banner-box-title"><i className="fa-brands fa-telegram mr-2"></i>Login via Telegram</span>
                    <span className="banner-box-text hide-on-mobile">To login to Aquabot via Telegram, you need to run a command from the telegram account you want to login as. Once you're logged in you'll be able to view and edit any skin checks you've completed from the telegram account you logged in as.</span>

                    <span className="banner-box-title mt-2">Step 1. Copy the command below</span>
                    <span className="banner-box-text">This code will authenticate your session on this browser</span>
                    <div className="login-code-box mt-2">
                        <div className="code-box">
                            <div className="login-code">{loginCommand}</div>
                        </div>
                        <div className="button-action-container">
                            <button className="nav-option-btn" disabled={loginCommand === 'loading'} onClick={() => copyCode()}>{copyText}</button>
                        </div>
                    </div>
                    <span className="banner-box-title mt-2">Step 2. Send it to @AquaSkinCheckerBot on Telegram</span>
                    <span className="banner-box-text">Send the message from the Telegram account you want to login as</span>
                    <div className="login-code-box ctr mt-2">
                        <button className="primary-btn" onClick={() => window.open('https://t.me/AquaSkinCheckerBot', '_blank')}>Open Telegram<i className="fa-solid fa-arrow-up-right-from-square ml-2"></i></button>
                    </div>
                    <span className="banner-box-title mt-2">Step 3. Wait for the page to update</span>
                    <span className="banner-box-text">Once you've sent your login to command to the bot, the page should update after a few seconds and you'll be redirected to your profile</span>
                    <div className="login-code-box ctr mt-2">
                        <button className="border-btn" disabled>Waiting for authorization...</button>
                    </div>
                </div> :

                <div>
                    <div className="select-login">
                        <img alt="logo" src="https://cdn.aquabot.io/logo.png" />

                        <span className="banner-box-title">Sign In</span>

                        <div className="login-options-container">
                            <div className="login-option-container" onClick={() => setSelectedSignInApp('telegram')}>
                                <div className="login-option-container-img tg-advert">
                                    <i className="fa-brands fa-telegram"></i>
                                </div>
                                <div className="login-option-container-text">
                                    Sign in with Telegram
                                </div>
                            </div>

                            <div className="login-option-container">
                                <div className="login-option-container-img discord-advert">
                                    <i className="fa-brands fa-discord"></i>
                                </div>
                                <div className="login-option-container-text">
                                    Sign in with Discord
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                
                <div style={{ textAlign: 'center' }}>
                    <button className="model-bottom-btn primary-btn mt-2" onClick={closeModal}>Cancel</button>
                </div>
        </Modal>
    )
};

const PrivacyModal = (props) => {
    const { modalIsOpen, setModalIsOpen, user, setUser } = props;

    const submitProfileChanges = () => {

    };

    return (
        <Modal isOpen={modalIsOpen} style={modalStyles} contentLabel="Login Modal">
            <div>                    
                <span className="banner-box-title"><i className="fa-solid fa-lock mr-2"></i>Privacy</span>
                <span className="banner-box-text">Settings to manage what's shown publiclly about accounts you skincheck</span>

                <span className="banner-box-title mt-2">Accounts you've checked</span>
                <span className="banner-box-text">A list of accounts you've checked will be shown on your public profile, with a link to view their lockers</span>
                <div>
                    <select className="b-input" id="private-profile-select">
                        <option value="true">Show accounts i've checked on my profile</option>
                        <option value="true">Hide accounts i've checked (private profile)</option>
                    </select>
                </div>

                <span className="banner-box-title mt-2">Account display names</span>
                <span className="banner-box-text">The Epicgames display names of accounts you've checked, when off, only the first and last two characters are shown</span>
                <div>
                    <select className="b-input" id="show-display-names-select">
                        <option value="true">Show display names of the accounts i've checked</option>
                        <option value="true">Hide display names of the accounts i've checked</option>
                    </select>
                </div>

                <div style={{ textAlign: "center" }}>
                    <button className="model-bottom-btn primary-btn mt-2" style={{ background: "#0278F4" }} onClick={() => submitProfileChanges()}>Submit</button>
                </div>
            </div>
        </Modal>
    )
};

const Layout = () => {
    const { user, setUser } = useContext(UserContext);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [privacyModalIsOpen, setPrivacyModalIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const openPrivacyModal = () => {
        setPrivacyModalIsOpen(true);
    };

    return (
        <>
            <nav className="nav">
                <img className="header-title" alt="logo" src="https://cdn.aquabot.io/logo.png" />
                <div className="nav-left">
                    <NavLink to="/" className={({ isActive }) => {
                        return (isActive ? "nav-option-active" : "");
                    }}><button className="nav-option">Home</button></NavLink>
                    <button className="nav-option" onClick={() => window.location.href = "https://aquabot.io"}>Store</button>
                    <NavLink to="/leaderboard" className={({ isActive }) => {
                        return (isActive ? "nav-option-active" : "");
                    }}><button className="nav-option">Leaderboard</button></NavLink>
                    <button className="nav-option">Telegram</button>
                    {user !== null ?
                        <button className="nav-option" onClick={openPrivacyModal}>Privacy</button>
                    : null }
                </div>
                <div className="nav-right">
                    {user === null
                        ?
                        <button className="nav-option-btn" onClick={openModal}><i className="fa-solid fa-right-to-bracket mr-2"></i>Login</button>
                        :
                        <Link to="/dashboard"><button className="nav-option-btn"><i className="fa-solid fa-user mr-2"></i>@{user.username}</button></Link>
                    }
                </div>
            </nav>
            {/* rgba(255,255,255,.08) */}

            <div className="page-content">
                <Outlet />
            </div>

            <AuthModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} user={user} setUser={setUser} />
            <PrivacyModal modalIsOpen={privacyModalIsOpen} setIsOpen={privacyModalIsOpen} user={user} />
        </>
    )
};

export default Layout;