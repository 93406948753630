import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import Select from 'react-select';

const Profile = () => {
    const [profileUsername, setProfileUsername] = useState('');
    const [profileImage, setProfileImage] = useState('https://cdn.aquabot.io/placeholder.png');
    const [checkCreatedAt, setCheckCreatedAt] = useState('');
    const [viewingCosmetic, setViewingCosmetic] = useState('skins');
    const [accountId, setAccountId] = useState('');
    const [userId, setuserId] = useState('');
    const [loaded, setLoaded] = useState(false);
    
    const [skincheck, setSkincheck] = useState({});
    const [items, setItems] = useState([]);

    const variantOptions = [
        { label: "All", value: "all" },
        { label: "Exclusive", value: "exclusive" },
        { label: "Frozen", value: "frozen" },
        { label: "Lava", value: "lava" },
        { label: "Legendary", value: "legendary" },
        { label: "Dark", value: "dark" },
        { label: "Marvel", value: "marvel" },
        { label: "DC", value: "dc" },
        { label: "Slurp", value: "slurp" },
        { label: "Starwars", value: "starwars" },
        { label: "Icon", value: "icon" },
        { label: "Shadow", value: "shadow" },
        { label: "Gaming Legends", value: "gaminglegends" },
        { label: "Epic", value: "epic" },
        { label: "Rare", value: "rare" },
        { label: "Uncommon", value: "uncommon" },
        { label: "Common", value: "common" }
    ];

    const setCosmetic = (viewingCosmetic) => {
        setViewingCosmetic(viewingCosmetic);
        setItems(skincheck[viewingCosmetic]);
    };

    const setRarityFilter = (rarity) => {
        if(rarity.value === "all") {
            setItems(skincheck[viewingCosmetic]);
            return;
        };
        setItems(skincheck[viewingCosmetic].filter(i => i.rarity === rarity.value));
    };

    useEffect(() => {
        const fetchData = async() => {
            const accountID = window.location.href.split('/')[4];
            const checkID = window.location.href.split('/').pop();
            setAccountId(accountID);

            const data = await fetch(`https://api-v2.aquabot.io/epicaccount/${accountID}/skinchecks/${checkID}`, { credentials: "include" }).then(r => r.json());
            if(data.success === false) {
                window.location.href = '/';
                return;
            };

            let { epicaccount, skincheck } = data;
            console.log(skincheck);

            setProfileUsername(epicaccount.display_name);
            setProfileImage(epicaccount.image_url);
            setCheckCreatedAt(skincheck.checked_at);
            setSkincheck(skincheck);
            setuserId(skincheck.user_id);

            await Promise.all(Object.keys(skincheck).map(async k => {
                if(!Array.isArray(skincheck[k])) return;
                await Promise.all(skincheck[k].map(s => {
                    if(s === false) return;
    
                    const image = new Image();
                    image.src = s.image;
    
                    return s;
                }));
            }));

            setItems(skincheck[viewingCosmetic]);
            setLoaded(true);
        };
        fetchData();
    }, []);

    if(loaded === false) {
        return (
            <div className="loading-page">
                <div className="loading-page-content">
                    <MoonLoader color="#0278F4" />
                </div>
            </div>
        )
    };

    return (
        <>
            <div className="skincheck-pg-content">
                <div className="skincheck-pg-wrapper">
                    <div className="profile-top-content">
                        <div className="banner-box profile-banner-box profile-banner-box-full">
                            <div className="banner-box-icon">
                                <img className="profile-box-image" alt="logo" src={profileImage} />
                            </div>
                            <div className="banner-box-content">
                                <span className="banner-box-title profile-box-title">{profileUsername}</span>
                                <span className="banner-box-text">{checkCreatedAt} ago • <Link className="leaderboard-link" to={`/epicaccounts/${accountId}`}>view old checks</Link> • <Link className="leaderboard-link" to={`/user/${userId}`}>view user profile</Link></span>
                            </div>
                        </div>
                    </div>
                    <div className="skincheck-cosm-selections">
                        <div className={`skincheck-cosm-type-btn ${viewingCosmetic === "skins" ? "skincheck-cosm-type-btn-active": ""}`} onClick={() => setCosmetic('skins')}>
                            <div className="skincheck-icon-container">
                                <div className="skincheck-icon icon-outfit"></div>
                            </div>
                            <p className="skincheck-name">Outfits</p>
                        </div>
                        <div className={`skincheck-cosm-type-btn ${viewingCosmetic === "backblings" ? "skincheck-cosm-type-btn-active": ""}`} onClick={() => setCosmetic('backblings')}>
                            <div className="skincheck-icon-container">
                                <div className="skincheck-icon icon-backpack"></div>
                            </div>
                            <p className="skincheck-name">Backblings</p>
                        </div>
                        <div className={`skincheck-cosm-type-btn ${viewingCosmetic === "pickaxes" ? "skincheck-cosm-type-btn-active": ""}`} onClick={() => setCosmetic('pickaxes')}>
                            <div className="skincheck-icon-container">
                                <div className="skincheck-icon icon-pickaxe"></div>
                            </div>
                            <p className="skincheck-name">Pickaxes</p>
                        </div>
                        <div className={`skincheck-cosm-type-btn ${viewingCosmetic === "gliders" ? "skincheck-cosm-type-btn-active": ""}`} onClick={() => setCosmetic('gliders')}>
                            <div className="skincheck-icon-container">
                                <div className="skincheck-icon icon-glider"></div>
                            </div>
                            <p className="skincheck-name">Gliders</p>
                        </div>
                        <div className={`skincheck-cosm-type-btn ${viewingCosmetic === "emotes" ? "skincheck-cosm-type-btn-active": ""}`} onClick={() => setCosmetic('emotes')}>
                            <div className="skincheck-icon-container">
                                <div className="skincheck-icon icon-emote"></div>
                            </div>
                            <p className="skincheck-name">Emotes</p>
                        </div>
                        <div className={`skincheck-cosm-type-btn ${viewingCosmetic === "wraps" ? "skincheck-cosm-type-btn-active": ""}`} onClick={() => setCosmetic('wraps')}>
                            <div className="skincheck-icon-container">
                                <div className="skincheck-icon icon-wrap"></div>
                            </div>
                            <p className="skincheck-name">Wraps</p>
                        </div>
                        <div className={`skincheck-cosm-type-btn ${viewingCosmetic === "sprays" ? "skincheck-cosm-type-btn-active": ""}`} onClick={() => setCosmetic('sprays')}>
                            <div className="skincheck-icon-container">
                                <div className="skincheck-icon icon-spray"></div>
                            </div>
                            <p className="skincheck-name">Sprays</p>
                        </div>
                        <div className={`skincheck-cosm-type-btn ${viewingCosmetic === "banners" ? "skincheck-cosm-type-btn-active": ""}`} onClick={() => setCosmetic('banners')}>
                            <div className="skincheck-icon-container">
                                <div className="skincheck-icon icon-banner"></div>
                            </div>
                            <p className="skincheck-name">Banners</p>
                        </div>
                        <div className={`skincheck-cosm-type-btn ${viewingCosmetic === "exclusives" ? "skincheck-cosm-type-btn-active": ""}`} onClick={() => setCosmetic('exclusives')}>
                            <div className="skincheck-icon-container">
                                <div className="skincheck-icon icon-emoji"></div>
                            </div>
                            <p className="skincheck-name">Exclusives</p>
                        </div>
                    </div>

                    <div className="skincheck-controls">
                        <input className="skincheck-controls-input" placeholder="Search" onChange={(e) => {
                            // if(!(e.target.length > 1)) {
                            //     return setItems(skincheck[viewingCosmetic]);
                            // }
                            setItems(skincheck[viewingCosmetic].filter(i => i.name.toLowerCase().includes(e.target.value.toLowerCase())));
                        }} />
                        <Select
                            placeholder="Rarity"
                            className="skincheck-controls-select"
                            isSearchable={false}
                            options={variantOptions}
                            onChange={setRarityFilter}
                        />
                    </div>

                    <div className="skincheck-items-abc">
                        {items.map(b => {
                            return (
                                <div key={b.id} className="skincheck-cosm-type-btn-item" style={{ background: `url(https://cdn.aquabot.io/backgrounds/${b.rarity}bg.png)`, backgroundSize: 'cover' }}>
                                    <div className="skincheck-icon-container-item">
                                        <img alt={`${viewingCosmetic}`} className={`skincheck-icon-item skincheck-icon-item-${viewingCosmetic} ${b.skin ? "skincheck-icon-item-skins" : ""}`} src={b.image} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

{/* 
            <span className="banner-box-title mt-2">Skin check history</span>
            <span className="banner-box-text">Click on an account to view it and it's skin check history</span> */}
{/* 
            {profileAccounts.map((a, index) => {
                return (
                    <div className="banner-box skin-check-banner-box mt-2 account-box">
                        <div className="banner-box-icon">
                            <img className="profile-box-image" alt="logo" src="https://fortnite-api.com/images/cosmetics/br/cid_035_athena_commando_m_medieval/icon.png" />
                        </div>
                        <div className="banner-box-content">
                            <div>
                                <span className="banner-box-title">{a.display_name}</span>
                                <span className="banner-box-text">last checked {a.last_checked} ago • first checked {a.first_checked} ago</span>
                            </div>
                            <div className="account-showcase">
                                <div className="account-cosmetic">
                                    <img src="https://cdn.aquabot.io/placeholder.png" />
                                </div>
                                <div className="account-cosmetic">
                                    <img src="https://cdn.aquabot.io/placeholder.png" />
                                </div>
                                <div className="account-cosmetic">
                                    <img src="https://cdn.aquabot.io/placeholder.png" />
                                </div>
                                <div className="account-cosmetic">
                                    <img src="https://cdn.aquabot.io/placeholder.png" />
                                </div>
                                <div className="account-cosmetic">
                                    <img src="https://cdn.aquabot.io/placeholder.png" />
                                </div>
                                <div className="account-cosmetic">
                                    <span className="banner-box-title account-box-stat-c">+ {a.skin_count} More</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })} */}
        </>
    )
};

export default Profile;