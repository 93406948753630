import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";

const LeaderboardRecord = (props) => {
    const { id, account_id, username, value, type, last_skin_check, index, indexStart } = props;

    return (
        <tr>
            <td>#{indexStart + index + 1}</td>
            <td><Link className="leaderboard-link" to={last_skin_check ? `/epicaccounts/${account_id}/skinchecks/${last_skin_check}` : `/user/${id}`}>@{username.length > 18 ? username.slice(0, 18) + "..." : username}</Link></td>
            <td>{value} {type}</td>
        </tr>
    )
};

const Leaderboard = (props) => {
    const { type, searchUsername } = props; 
    const [leaderboardTitle, setLeaderboardTitle] = useState('');
    const [leaderboard, setLeaderboard] = useState([]);
    const [lbType, setLbType] = useState(type);
    const [page, setPage] = useState(0);
    const [loaded, setLoaded] = useState(false);

    const getLeadeboardContent = async() => {
        const leaderboardType = type;
        setLoaded(false);
        setLbType(leaderboardType);

        const data = await fetch(`https://api-v2.aquabot.io/leaderboard/${leaderboardType}?skip=${page * 100}${searchUsername !== null ? "&username=" + searchUsername : ""}`).then(r => r.json());
        if(data.success === false) {
            alert(data.error);
            return;
        };

        setLeaderboardTitle(data.leaderboard_title);
        setLeaderboard(data.leaderboard);
        setLoaded(true);
    };

    useEffect(() => {
        (async() => {
            await getLeadeboardContent();
        })();
    }, [page, searchUsername]);

    console.log(lbType);

    if(loaded === false) {
        return (
            <div className="loading-page">
                <div className="loading-page-content">
                    <MoonLoader color="#0278F4" />
                </div>
            </div>
        )
    };

    return (
        <>
            <div className="leaderboard-box leaderboard-d-box mb-2">
                <div className="leaderboard-title">
                    <span className={`banner-box-title leaderboard-text-title leaderboard-d-text-title leaderboard-text-title-${type}`}>{leaderboardTitle}</span>
                </div>


                <div className="leaderboard-content"  style={{ overflowY: "hidden" }}>
                    <table className="leaderboard-table">
                        <thead>
                            <tr className="header-row">
                                <th>#</th>
                                <th>Username</th>
                                <th>Total {lbType}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lbType === "checks" ?
                                <>
                                    {leaderboard.map((l, i) => {
                                        return (
                                            <LeaderboardRecord key={`${Math.random().toString()}-${l._id}`} indexStart={page * 100} id={l._id} username={l.username} value={l.total_skin_checks} type="" index={i} />
                                        )
                                    })}
                                </>
                            : <>
                                {leaderboard.map((l, i) => {
                                    return (
                                        <LeaderboardRecord key={`${Math.random().toString()}-${l._id}`} indexStart={page * 100} id={l._id} account_id={l.account_id} username={l.username} value={`$${l.value}`} last_skin_check={l.last_skin_check} type="" index={i} />
                                    )
                                })}
                            </>}
                            
                        </tbody>
                    </table>
                    <div className="leaderboard-table-footer">
                    <button className="table-btn" onClick={() => setPage(page - 1)} disabled={page === 0}>Last Page</button>
                        <button className="table-btn ml-2" onClick={() => setPage(page + 1)}>Next Page</button>
                    </div>
                </div>
            </div>
        </>
    )
};

const LeaderboardPage = () => {
    const [searchUsername, setSearchUsername] = useState(null);  

    return (
        <>
            <div className="leaderboard-page-title-content">
                <span className="leaderboard-page-title-text">Aquabot Checker</span>
                <span className="leaderboard-page-title-subtext">Leaderboard</span>

                <div className="leaderboard-search-txwrap">
                    <div className="leaderboard-search-content mt-1">
                        <div>
                            <input className="skincheck-controls-input lb-search-input" id="search-username" placeholder="aquabot.io username" />
                        </div>
                        <div>
                            <button className="primary-btn lb-search-button" onClick={() => setSearchUsername(document.getElementById("search-username").value)}>Search</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="leaderboard-page-content">
                <Leaderboard searchUsername={searchUsername} type="checks" />
                <Leaderboard searchUsername={searchUsername} type="value" />
            </div>
        </>
    )
}

export default LeaderboardPage;