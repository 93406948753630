import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const LeaderboardRecord = (props) => {
    const { id, account_id, username, value, type, last_skin_check, index } = props;

    return (
        <tr>
            <td>#{index + 1}</td>
            <td><Link className="leaderboard-link" to={last_skin_check ? `/epicaccounts/${account_id}/skinchecks/${last_skin_check}` : `/user/${id}`}>@{username}</Link></td>
            <td className="center-on-mobile">{value}</td>
        </tr>
    )
};

const Index = () => {
    const [valueLeaderboard, setValueLeaderboard] = useState([]);
    const [checksLeaderboard, setChecksLeaderboard] = useState([]);
    const [announcementImage, setAnnouncementImage] = useState('');
    const [announcementTitle, setAnnouncementTitle] = useState('');
    const [announcementDescription, setAnnouncementDescription] = useState('');
    const [announcementExternalLink, setAnnouncementExternalLink] = useState('');
    const [welcomeText, setWelcomeText] = useState('');
    const [telegramText, setTelegramText] = useState('');
    const [discordText, setDiscordText] = useState('');
     
    useEffect(() => {
        (async() => {
            const data = await fetch("https://api-v2.aquabot.io/home/leaderboard", { credentials: "include" }).then(r => r.json());
            if(data.success === false) return;

            const leaderboardValue = data.static.leaderboard_value;
            setValueLeaderboard(leaderboardValue);

            const leaderboardChecks = data.static.leaderboard_checks;
            setChecksLeaderboard(leaderboardChecks);
        })();
        (async() => {
            const data = await fetch("https://api-v2.aquabot.io/home/content", { credentials: "include" }).then(r => r.json());
            if(data.success === false) return;

            setAnnouncementImage(data.announcement.image_url);
            setAnnouncementTitle(data.announcement.title);
            setAnnouncementDescription(data.announcement.description);
            setAnnouncementExternalLink(data.announcement.external_url);
            setWelcomeText(data.welcome_text);
            setTelegramText(data.telegram_text);
            setDiscordText(data.discord_text);
        })();
    }, []);

    return (
        <>
            <div className="banner-box">
                <div className="banner-box-icon">
                    <div className="banner-box-icon-text home-banner-icon">👋</div>
                </div>
                <div className="banner-box-content banner-box-index-content">
                    <div className="banner-box-title">Welcome to the new Aquabot website!</div>
                    <div className="banner-box-text">{welcomeText}</div>
                </div>
            </div>

            <div className="main-pg-content">
                <div className="row h-100 main-pg-row">
                    <div className="col-6">
                        <div className="announcement-container">
                            <div className="announcement-content h-100">
                                <div className="announcement-header">
                                    <div className="banner-box-title">Announcement</div>

                                    <div className="announcement-btn-container">
                                        <button className="table-btn" onClick={() => window.location.href = announcementExternalLink}>View</button>
                                    </div>
                                </div>
                                <div className="announcement-main">
                                    <img className="announcement-main-img" alt="announcement" src={announcementImage} />
                                </div>
                                <div className="announcement-caption">
                                    <div className="banner-box-title">{announcementTitle}</div>
                                    <div className="banner-box-text">{announcementDescription}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="row h-100">
                            <div className="col-6">
                                <div className="leaderboard-box leaderboard-d-box">
                                    <div className="leaderboard-title">
                                        <span className="banner-box-title leaderboard-text-title leaderboard-text-title-checks">Top Skin Checks</span>
                                    </div>

                                    <div className="leaderboard-content">
                                        <table className="leaderboard-table">
                                            <thead>
                                                <tr className="header-row">
                                                    <th>#</th>
                                                    <th>Username</th>
                                                    <th>Total Checks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {checksLeaderboard.map((l, i) => {
                                                    return (
                                                        <LeaderboardRecord key={`${Math.random().toString()}-${l._id}`} id={l._id} username={l.username} value={l.total_skin_checks} last_skin_check={false} type="checks" index={i} />
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        <div className="leaderboard-table-footer">
                                            <Link to="/leaderboard"><button className="table-btn">More</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="leaderboard-box leaderboard-d-box">
                                    <div className="leaderboard-title">
                                    <span className="banner-box-title leaderboard-text-title leaderboard-text-title-value">Top Valued Lockers</span>
                                    </div>

                                    <div className="leaderboard-content">
                                        <table className="leaderboard-table">
                                            <thead>
                                                <tr className="header-row">
                                                    <th>#</th>
                                                    <th>Username</th>
                                                    <th>Locker Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {valueLeaderboard.map((l, i) => {
                                                    return (
                                                        <LeaderboardRecord key={`${Math.random().toString()}-${l._id}`} id={l._id} account_id={l.account_id} username={l.username} value={`$${l.value}`} last_skin_check={l.last_skin_check} type="" index={i} />
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        <div className="leaderboard-table-footer">
                                        <Link to="/leaderboard"><button className="table-btn">More</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-6">
                    <div className="banner-box tg-advert">
                        <div className="banner-box-icon">
                            <span className="banner-box-icon-text"><i className="fa-brands fa-telegram"></i></span>
                        </div>
                        <div className="banner-box-content">
                            <span className="banner-box-title">Join Aquabot on Telegram</span>
                            <span className="banner-box-text">{telegramText}</span>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="banner-box discord-advert">
                        <div className="banner-box-icon">
                            <span className="banner-box-icon-text"><i className="fa-brands fa-discord"></i></span>
                        </div>
                        <div className="banner-box-content">
                            <span className="banner-box-title">Join Aquabot on Discord</span>
                            <span className="banner-box-text">{discordText}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Index;