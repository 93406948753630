import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";

const Profile = () => {
    const [profileUsername, setProfileUsername] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [profileTotalValue, setProfileTotalValue] = useState(0);
    const [profileLastMonthValue, setProfileLastMonthValue] = useState(0);
    const [profileTotalSkinChecks, setProfileTotalSkinChecks] = useState(0);
    const [profileLastMonthSkinChecks, setProfileLastMonthSkinChecks] = useState(0);
    const [profileLastSkinCheck, setProfileLastSkinCheck] = useState(0);
    const [profileAccounts, setProfileAccounts] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const fetchData = async() => {
            const userID = window.location.href.split('/').pop();

            const data = await fetch(`https://api-v2.aquabot.io/user/${userID}`, { credentials: "include" }).then(r => r.json());
            if(data.success === false) {
                window.location.href = '/';
                return;
            };

            const { user } = data;

            setProfileUsername(user.username);
            setProfileImage(user.image_url);
            setProfileTotalValue(user.total_value);
            setProfileLastMonthValue(user.total_value);
            setProfileTotalSkinChecks(user.total_skin_checks);
            setProfileLastMonthSkinChecks(user.total_skin_checks_last_month);
            setProfileLastSkinCheck(user.last_skin_check);
            setProfileAccounts(user.account_values);
            setLoaded(true);
        };
        fetchData();
    }, []);

    if(loaded === false) {
        return (
            <div className="loading-page">
                <div className="loading-page-content">
                    <MoonLoader color="#0278F4" />
                </div>
            </div>
        )
    };

    return (
        <div className="mb-2">
            <div className="profile-top-content">
                <div className="banner-box profile-banner-box">
                    <div className="banner-box-icon">
                        <img className="profile-box-image" alt="logo" src={profileImage} />
                    </div>
                    <div className="banner-box-content">
                        <span className="banner-box-title profile-box-title">@{profileUsername}</span>
                        <span className="banner-box-text">last skin checked {profileLastSkinCheck} ago</span>
                    </div>
                </div>
                <div className="profile-stats-container">
                    <div className="profile-stat-container">
                        <div className="banner-box-icon">
                            <span className="banner-box-icon-text pf-bx-ic-tx"><i className="fa-solid fa-sack-dollar"></i></span>
                        </div>
                        <div className="banner-box-content">
                            <span className="stat-box-title">Total Accounts Value</span>
                            <span className="stat-box-text">${Math.round(profileTotalValue)}</span>
                        </div>
                    </div>
                    <div className="profile-stat-container">
                        <div className="banner-box-icon">
                            <span className="banner-box-icon-text pf-bx-ic-tx"><i className="fa-regular fa-calendar-days"></i></span>
                        </div>
                        <div className="banner-box-content">
                            <span className="stat-box-title">Total Value This Month</span>
                            <span className="stat-box-text">${Math.round(profileLastMonthValue)}</span>
                        </div>
                    </div>
                    <div className="profile-stat-container">
                        <div className="banner-box-icon">
                            <span className="banner-box-icon-text pf-bx-ic-tx"><i className="fa-solid fa-list"></i></span>
                        </div>
                        <div className="banner-box-content">
                            <span className="stat-box-title">Total Skin Checks</span>
                            <span className="stat-box-text">{profileTotalSkinChecks}</span>
                        </div>
                    </div>
                    <div className="profile-stat-container">
                        <div className="banner-box-icon">
                            <span className="banner-box-icon-text pf-bx-ic-tx"><i className="fa-solid fa-calendar-week"></i></span>
                        </div>
                        <div className="banner-box-content">
                            <span className="stat-box-title">Skin Checks This Month</span>
                            <span className="stat-box-text">{profileLastMonthSkinChecks}</span>
                        </div>
                    </div>
                </div>
            </div>

            <span className="banner-box-title mt-2">Skin check history</span>
            <span className="banner-box-text">Click on an account to view it and it's skin check history</span>

            {profileAccounts.map((a, index) => {
                return (
                    <Link to={`/epicaccounts/${a.id}/skinchecks/${a.last_check_id}`} key={a.last_check_id}>
                        <div className="banner-box skin-check-banner-box mt-2 account-box">
                            <div className="banner-box-icon">
                                <img className="profile-box-image" alt="logo" src={a.image_url} />
                            </div>
                            <div className="banner-box-content">
                                <div>
                                    <span className="banner-box-title">{a.display_name}</span>
                                    <span className="banner-box-text">last checked {a.last_checked} ago • first checked {a.first_checked} ago</span>
                                </div>
                                <div className="account-showcase">
                                    {a.account_highlights.map((h) => {
                                        return (
                                            <div className="account-cosmetic" key={`${Math.random().toString()}-${h.image_url}`}>
                                                <img alt="account highlight" src={h.image_url} />
                                            </div>
                                        )
                                    })}
                                    <div className="account-cosmetic">
                                        {a.skin_count > 0 ?
                                        <span className="banner-box-title account-box-stat-c">+ {a.skin_count - a.account_highlights.length} More</span>
                                        :
                                        <span className="banner-box-title account-box-stat-c">0 skins</span>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                )
            })}
        </div>
    )
};

export default Profile;