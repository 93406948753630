import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";

const EpicAccount = () => {
    const [epicAccountDisplayName, setEpicAccountDisplayName] = useState('');
    const [epicAccountImage, setEpicAccountImage] = useState('');
    const [epicAccountValue, setEpicAccountValue] = useState(0);
    const [epicAccountCheckedByUsers, setEpicAccountCheckedByUsers] = useState(0);
    const [epicAccountLastSkinCheck, setEpicAccountLastSkinCheck] = useState(0);
    const [skincheckHistory, setSkincheckHistory] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const fetchData = async() => {
            const accountID = window.location.href.split('/')[4];

            const data = await fetch(`https://api-v2.aquabot.io/epicaccount/${accountID}`, { credentials: "include" }).then(r => r.json());
            if(data.success === false) {
                window.location.href = '/';
                return;
            };

            const { epicaccount, skinchecks } = data;
            setEpicAccountDisplayName(epicaccount.display_name);
            setEpicAccountImage(epicaccount.image_url);
            setEpicAccountValue(epicaccount.value);
            setEpicAccountCheckedByUsers(epicaccount.checked_by_users)
            setEpicAccountLastSkinCheck(epicaccount.last_checked);

            setSkincheckHistory(skinchecks);


            // setProfileUsername(user.telegram_username);
            // setProfileImage(user.image_url);
            // setProfileTotalValue(user.total_value);
            // setProfileLastMonthValue(user.total_value);
            // setProfileTotalSkinChecks(user.total_skin_checks);
            // setProfileLastMonthSkinChecks(user.total_skin_checks_last_month);
            // setProfileLastSkinCheck(user.last_skin_check);
            // setProfileAccounts(user.account_values);
            setLoaded(true);
        };
        fetchData();
    }, []);

    if(loaded === false) {
        return (
            <div className="loading-page">
                <div className="loading-page-content">
                    <MoonLoader color="#0278F4" />
                </div>
            </div>
        )
    };

    return (
        <div className="mb-2">
            <div className="profile-top-content">
                <div className="banner-box profile-banner-box">
                    <div className="banner-box-icon">
                        <img className="profile-box-image" alt="logo" src={epicAccountImage} />
                    </div>
                    <div className="banner-box-content">
                        <span className="banner-box-title profile-box-title">{epicAccountDisplayName}</span>
                        <span className="banner-box-text">last skin checked {epicAccountLastSkinCheck} ago</span>
                    </div>
                </div>
                <div className="profile-stats-container">
                    <div className="profile-stat-container">
                        <div className="banner-box-icon">
                            <span className="banner-box-icon-text pf-bx-ic-tx"><i className="fa-solid fa-sack-dollar"></i></span>
                        </div>
                        <div className="banner-box-content">
                            <span className="stat-box-title">Current Account Value</span>
                            <span className="stat-box-text">${Math.round(epicAccountValue)}</span>
                        </div>
                    </div>
                    <div className="profile-stat-container">
                        <div className="banner-box-icon">
                            <span className="banner-box-icon-text pf-bx-ic-tx"><i className="fa-solid fa-user"></i></span>
                        </div>
                        <div className="banner-box-content">
                            <span className="stat-box-title">Checked By Users</span>
                            <span className="stat-box-text">{epicAccountCheckedByUsers}</span>
                        </div>
                    </div>
                    <div className="profile-stat-container">
                        <div className="banner-box-icon">
                            <span className="banner-box-icon-text pf-bx-ic-tx"><i className="fa-solid fa-list"></i></span>
                        </div>
                        <div className="banner-box-content">
                            <span className="stat-box-title">Total Skin Checks</span>
                            <span className="stat-box-text">{skincheckHistory.length}</span>
                        </div>
                    </div>
                    <div className="profile-stat-container">
                        <div className="banner-box-icon">
                            <span className="banner-box-icon-text pf-bx-ic-tx"><i className="fa-solid fa-calendar-week"></i></span>
                        </div>
                        <div className="banner-box-content">
                            <span className="stat-box-title">Skin Checks This Month</span>
                            <span className="stat-box-text">{skincheckHistory.length}</span>
                        </div>
                    </div>
                </div>
            </div>

            <span className="banner-box-title mt-2">Skin check history</span>
            <span className="banner-box-text">Click on a skin check to view it</span>

            {skincheckHistory.map((a, index) => 
                    <Link to={`/epicaccounts/${a.id}/skinchecks/${a.check_id}`} key={a.check_id}>
                        <div className="banner-box skin-check-banner-box mt-2 account-box">
                            <div className="banner-box-icon">
                                <img className="profile-box-image" alt="logo" src={a.image_url} />
                            </div>
                            <div className="banner-box-content">
                                <div>
                                    <span className="banner-box-title">{a.display_name}</span>
                                    <span className="banner-box-text">{a.checked_at} ago</span>
                                </div>
                                <div className="account-showcase">
                                    {a.account_highlights.map(h => {        
                                        return (
                                            <div className="account-cosmetic" key={`${Math.random().toString()}-${h.image_url}`}>
                                                <img alt="account highlight" src={h.image_url} />
                                            </div>
                                        )
                                    })}
                                    <div className="account-cosmetic">
                                        {a.skin_count > 0 ?
                                        <span className="banner-box-title account-box-stat-c">+ {a.skin_count - a.account_highlights.length} More</span>
                                        :
                                        <span className="banner-box-title account-box-stat-c">0 skins</span>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
            )}
        </div>
    )
};

export default EpicAccount;