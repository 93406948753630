import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useCookies } from "react-cookie";

import "./scss/app.scss";

import Layout from './components/Layout';
import Index from './pages/Index';
import Profile from './pages/Profile';
import Skincheck from './pages/Skincheck';
import EpicAccount from "./pages/EpicAccount";
import Leaderboard from "./pages/Leaderboard";
import Dashboard from './pages/Dashboard';

import { UserContext } from "./contexts/user";

const App = () => {
    const [user, setUser] = useState(null);
    const [cookies, setCookie] = useCookies(['loggedin', 'user_id', 'username', 'admin']);

    useEffect(() => {
        if(cookies["loggedin"] !== undefined && cookies["user_id"] !== undefined && cookies["telegram_username"] !== undefined && cookies["telegram_user_id"]) {
            setUser({
                loggedin: cookies["loggedin"],
                id: cookies["user_id"],
                username: cookies["username"]
            });

            const verifySession = async() => {
                const data = await fetch("https://api-v2.aquabot.io/user", { credentials: "include" }).then(r => r.json());
                if(data.success === false) {
                    setUser(null);
                    return;
                };
            };
            verifySession();
        };
    }, []);

    return (
        <BrowserRouter>
            <UserContext.Provider value={{ user: user, setUser: setUser }}>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Index />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/user/:id" element={<Profile />} />
                        <Route path="/epicaccounts/:id" element={<EpicAccount />} />
                        <Route path="/epicaccounts/:id/skinchecks/:id" element={<Skincheck />} />
                        <Route path="/leaderboard" element={<Leaderboard />} />
                    </Route>
                </Routes>
            </UserContext.Provider>
        </BrowserRouter>
    )
};

export default App;